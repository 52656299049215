.home {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  flex-direction: row;
}

.product_card {
  width: 280px;
  height: 400px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
  transition: 0.3s;
  border: 2px solid rgb(236, 236, 236);
  position: relative;
}

.product_card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
}

.product_card .product_info {
  padding: 10px;
  font: 14px "Poppins", sans-serif;
}

.colors {
  display: flex;
  gap: 5px;
  margin: 5px 0;
  align-items: center;
}

.colors .color {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}

.colors .color:nth-child(1) {
  border: 2px solid rgb(252, 252, 252);
  box-shadow: 0 0 0 1px rgba(37, 37, 37, 0.505);
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.bottom .price {
  font-size: 20px;
  font-weight: 600;
}

.bottom .add_to_cart {
  padding: 8px 15px;
  border-radius: 20px;
  border: none;
  background: #fff;
  color: #111111;
  font-size: 14px;
  cursor: pointer;
  border: 3px solid rgba(0, 0, 0, 0.17);
  transition: 0.3s ease;
}

.add_to_cart:hover {
  background: #111111b8;
  color: #fff;
}
.add_to_wishlist {
  position: absolute;
  padding: 5px;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: #ffffff9b;
  color: #111111;
  border: none;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.product_description {
  font-size: 14px;
  color: #292828;
  margin-top: 5px;
}
#productpage {
  margin-top: 130px;
}
#productpage-web {
  margin-top: 130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.product-header {
  background-color: #ff6347;
  color: white;
  text-align: center;
  padding: 1rem 0;
}

.product-main {
  flex: 1;
  padding: 2rem;
}

.product-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.product-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  margin: 1rem;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.product-card-content {
  padding: 1rem;
}

.product-card-content h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.product-card-content p {
  color: #555;
}
.product-main {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}
/* -------------------------filter-main--------------------------- */
.filter-main-web {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50%;
}
.filter-main-mobile {
  display: none;
}
#requestform {
  margin-top: 50px;
}

@media (max-width: 768px) {
  #requestform {
    margin-top: 130px;
  }
  #productpage {
    margin-top: 80px;
  }
  #productpage-web {
    margin-top: 130px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  /* -------------------------navbar start--------------------------- */
  /* .search-bar {
      border: 1px solid green;
    }
    .search-bar button {
      border: none;
    } */
  .site-mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    overflow-y: scroll;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }
  .js-clone-nav {
    /* background-color: rgb(18 118 94 / 10%); */
    border-radius: 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    padding: 10px;
  }
  .js-clone-nav li {
    list-style-type: none;
    margin-top: 0px;
    /* background-color: #feb900; */
    padding: 10px 0px 10px 10px;
  }
  .js-clone-nav .nav-link {
    color: #173e79;
    text-decoration: none; /* Ensure links have no underline by default */
  }

  .js-clone-nav .nav-link:hover {
    color: #173e79;
  }
  .js-clone-nav .nav-link:active {
    color: #173e79;
  }
  .site-mobile-menu.active {
    display: block;
    transform: translateX(0);
  }

  .site-mobile-menu-close {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 15px;
  }

  /* .has-children > .dropdown {
      display: none;
      position: absolute;
      background-color: #ffff;
      border-radius: 10px;
      text-align: left;
      padding: 0px 10px;
      width: 250px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    } */
  .has-children > .dropdown {
    display: none;
    position: absolute;
    background-color: #ffff;
    border-radius: 10px;
    text-align: left;
    padding: 0px 10px;
    width: 220px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-height: 300px; /* Set a maximum height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .has-children ul {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    color: #111111;
    width: 180px;
    padding: 10px 0px;
    text-align: center;
  }
  .has-children .dropdown-li {
    color: #111111;
  }

  .has-children .dropdown-li:hover,
  .has-children .dropdown-li:active {
    /* Change to blue on hover or when active */
  }

  .has-children.active > .dropdown {
    display: block;
  }

  .site-menu-toggle {
    background-color: transparent;
    border: none;
    right: 10px;
    margin-left: 5px;
  }
  .icon-menu {
    color: white; /* Initial color */
    font-size: 25px;

    transition: color 0.3s; /* Smooth transition for the color change */
  }

  .icon-menu.scrolled {
    color: #292974; /* Color when scrolled */
  }

  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    flex-direction: column;
  }
  .header-navcontact {
    font-size: 13px;
  }
  .social-media {
    display: flex;
    float: left;
    margin-top: 3px;
  }
  .social-media li {
    margin-left: 33px;
  }
  /* -------------------------navbar end--------------------------- */
  /* -------------------------filter-main--------------------------- */
  .filter-main-web {
    display: none;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  /* -------------------------filter-mobile--------------------------- */
  .filter-main-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-main-mobile button {
    border: #292828;

    padding: 3px 20px;
    border-radius: 25px 25px;
    font-size: 20px;
    color: black;
    margin-left: 20px;
  }
}
/* Navbar */
.containers {
  /* width: 1000px; */
  display: flex;
  margin-left: 110px;
}
.containers ul {
  margin-left: 100px;
}

.site-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  z-index: 1999;
  position: fixed;
  width: 100%;
  padding: 1rem 2rem;
  background: white;

  top: 0;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
/* .site-navigation Link {
    color: white;
  } */

.site-navigation {
  color: var(--nav-color);
  font-size: 14px;
  padding: 0 2px;
  font-family: "Futura Light BT", sans-serif !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-end;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
  text-transform: uppercase;
  width: 100%;
  right: 0%;
  /* margin-left: 400px; */
}

@keyframes underlineAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.site-menu .nav-link {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease;
  color: inherit;
  text-decoration: none;
}

.site-menu .nav-link::after {
  content: "";
  position: absolute;
  left: 0%;
  bottom: -2px; /* Adjust this value to position the underline */
  height: 2px; /* Thickness of the underline */
  width: 0;
  background-color: #173e79; /* Change the color to whatever you prefer */
  transition: width 0.3s ease;
}

.site-menu .nav-link:hover::after {
  width: 80%;
  animation: underlineAnimation 0.3s forwards;
}

.site-menu .nav-link.default-active::after {
  width: 80%;
  left: 10%;
}

.site-navbar.scrolled {
  /* background-color: #fff;
  box-shadow: 0 2px 4px #0000001a; */
  background-color: transparent;
  color: white;
}
/* .site-navbar .site-navigation .site-menu .scrolled > li > a {
    padding: 5px 20px;
    color: rgb(192, 45, 45);
    display: inline-block;
    text-decoration: none !important;
  } */
.site-navbar .site-logo {
  position: relative;
  left: -80px;
  top: -5px;
}

.site-navbar .site-navigation.border-bottom {
  border-bottom: 1px solid #f3f3f4 !important;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu .active {
  color: white;
  display: inline-block;
  padding: 5px 20px;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
  text-transform: lowercase;
  line-height: 26px;
}

.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}
/* .underline {
   padding: 0px 10px;
    border-bottom: 1px solid yellow;
  } */

.site-navbar .site-navigation .site-menu > li > a {
  padding: 5px 20px;
  color: black;
  display: inline-block;
  text-decoration: none !important;
  transition: color 0.3s ease;
}
.site-navbar.scrolled .site-navigation .site-menu > li > a {
  color: #ffff;
}

/* .site-navbar .site-navigation .site-menu > li > a:hover {
    
    color: black;
  } */

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 17px;
}

.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  /* content: "\e313"; */
  font-size: 16px;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Futura Light BT", sans-serif !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  display: flex;

  /* visibility: hidden; */

  opacity: 0;
  /* top: 100%; */
  position: absolute;
  height: auto;
  text-align: left;
  border-top: 2px solid #32dbc6;
  -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #edf0f5;
  border-right: 1px solid #edf0f5;
  border-bottom: 1px solid #edf0f5;
  padding: 0px 0;
  margin-top: 20px;
  margin-left: 0px;
  background: #fff;
  transition: visibility 0.2s, opacity 0.2s;
  width: auto;
}

.site-navbar .site-navigation .site-menu .has-children:hover .dropdown {
  visibility: visible;
  opacity: 1;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  -webkit-transition: 0s all;
  -o-transition: 0s all;
  transition: 0s all;
  color: #343a40;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 500px;
  /* position: fixed; */
}
.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #f4f5f9;
  color: #25262a;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: "\e315";
  right: 20px;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #f4f5f9;
  color: #25262a;
}
/* .site-navbar .site-navigation .site-menu .has-children:hover > a,
  .site-navbar .site-navigation .site-menu .has-children:focus > a,
  .site-navbar .site-navigation .site-menu .has-children:active > a {
    color: #32dbc6;
  } */
.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}
.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  margin-top: 0px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 10px;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.offcanvas-menu .site-mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
  color: #25262a;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}
.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}
.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}
.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
  color: #212529;
}
/* .site-mobile-menu .site-nav-wrap a:hover {
    color: #32dbc6;
  } */
.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}
/* .site-mobile-menu .site-nav-wrap li .active {
    color: #32dbc6;
  } */
.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
  background: #f8f9fa;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: "Futura Light BT", sans-serif !important;
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-180deg);
  -ms-transform: translate(-50%, -50%) rotate(-180deg);
  transform: translate(-50%, -50%) rotate(-180deg);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}
.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}
.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}
.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 5em;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li {
  width: auto;
}
.site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
  padding-left: 15px !important;
}

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}
.sticky-wrapper + .site-blocks-cover {
  margin-top: 96px;
}
.sticky-wrapper .site-navbar {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.sticky-wrapper.is-sticky .site-navbar {
  -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
}
.sticky-wrapper .shrink {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.site-menu .dropdown-menu {
  top: 90%;
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: nowrap;
  min-width: 350px; /* Increase width as needed */
}

@media (max-width: 786px) {
  .site-menu .dropdown-menu {
    background-color: #fff;
  }
}

.site-menu .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  background-color: transparent;
}

.site-menu .dropdown-menu .dropdown-item:last-child {
  border-bottom: none; /* Remove border from last item */
}

.site-menu .dropdown-menu .image-preview {
  display: none;
  position: absolute;
  right: 100%;
  top: 0;
  width: 200px;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.site-menu .dropdown-menu .image-preview img {
  width: 100%;
  height: auto;
}

.site-menu .dropdown-menu .dropdown-item:hover .image-preview {
  display: block;
}

.site-menu .dropdown-menu li {
  margin: 0;
  padding: 0;
  position: relative; /* Ensure proper positioning of image preview */
}

.site-menu .dropdown-menu .dropdown-link {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

.site-menu .dropdown-menu .dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Header.css */

/* Styles for the dropdown menu */
.dropdown-menus {
  position: absolute; /* Position the dropdown relative to the parent */
  top: 100%; /* Position dropdown below the menu item */
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 0; /* Remove padding to ensure images fit */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

#works-dropdown {
  width: 600px; /* Desired width of the dropdown */
  transform: translateX(
    calc(-20% + 10px)
  ); /* Shift the dropdown menu to the left */
}

#works-dropdown li {
  padding: 10px 0px;
}

/* Container for images, positioned at the top */
.dropdown-images {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap to the next line */
  gap: 10px; /* Space between images */
  margin-left: 0px; /* No margin to keep images at the top */
  padding: 10px; /* Space inside the container */
}

/* Styles for images inside the dropdown */
.dropdown-images img {
  width: 128px; /* Fixed width for all images */
  height: 130px; /* Fixed height for all images */
  object-fit: cover; /* Maintain aspect ratio and cover the entire area */
  border-radius: 2px;
}

.dropdown-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  min-width: 150px; /* Adjust as needed */
}

/* Remove borders or lines between items */
.dropdown-item {
  border: none; /* Remove borders */
  padding: 10px 15px; /* Add padding for spacing */
  display: flex;
  align-items: center;
}

/* Hide the dropdown-item by default, showing images only */
.dropdown-menu > .dropdown-item {
  display: none; /* Hide dropdown items by default */
}

/* Show images and the hovered item */
.dropdown-item:hover + .dropdown-images,
.dropdown-images:hover {
  display: flex; /* Show images when hovering over the item */
}

.image-card-about {
  position: fixed;
  top: 77px;
  right: 15%; /* Position to the right of the dropdown item */
  display: flex;
  flex-direction: row; /* Stack images from right to left */
  align-items: flex-start;
  /* display: grid; */
  /* grid-template-columns: repeat(4, 1fr);  */
  background: rgba(0, 0, 0, 0.7);
  height: auto;
  /* border: 1px solid #ddd; */
  padding: 7px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
}

.image-card-about img {
  object-fit: cover; /* Maintain aspect ratio and cover the entire area */
  margin-left: 5px; /* Space between images */
  display: block; /* Ensure images don't stretch */
  flex: 0 0 100px;
  margin-bottom: 15px;
  border-radius: 2px;
}
.image-card-about-dropdown {
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
}
.image-card-about p {
  text-transform: lowercase;
  font-size: 16px;
  font-weight: 150px;
  line-height: 18px;
  margin-bottom: 7px;
}

.site-logo .sitename {
  font-size: 22.5px;
  font-family: "Futura Light BT", sans-serif !important;
  font-weight: 600;
  width: 283px;
  margin: 0;
  letter-spacing: 2px;
}

.site-logo .logo-img {
  display: none;
}

.site-logo .subtext {
  font-size: 15px;
  line-height: 24px;
  word-spacing: 3px;
}

@media (max-width: 767px) {
  .site-logo .sitename {
    font-size: 16px;
    width: 212px;
  }

  .site-logo .subtext {
    font-size: 11.2px;
    line-height: 16px;
  }

  .site-logo .logo-img {
    display: flex;
    height: 46px;
    margin-left: 0px;
  }

  .image-card-about-dropdown {
    display: flex;
    flex-direction: column;
    color: #fff;
    text-align: center;
    margin-bottom: 5px;
  }

  .image-card-about p {
    text-transform: lowercase;
    font-size: 15px;
    font-weight: 300px;
    line-height: 18px;
  }
}

@media (max-width: 786px) {
  .site-mobile-menu .image-card img {
    width: 48%;
    margin: 1%;
    border-radius: 4px;
  }
  .image-card {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px; /* Adjust height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
  }
}

.container-test {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.logo-test {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 45%;
}
.menu-test {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
