.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* -------------------------------custom css------------------------------------ */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  box-sizing: border-box;
}
/* body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #f7f8fc;
  font-family: "Roboto", sans-serif;
  color: #10182f;
} */
.container.blog-card {
  display: flex;
  width: 1040px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}
.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tag-teal {
  background-color: #47bcd4;
}
.tag-purple {
  background-color: #5e76bf;
}
.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}

/* ----------------------about us---------------------- */
.section_all h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c3145;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  font-family: "Futura Light BT", sans-serif !important;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.section_all {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 100vh;
}
.section-title {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.section-subtitle {
  letter-spacing: 0.4px;
  line-height: 28px;
  max-width: 550px;
}

.section-title-border {
  background-color: #000;
  height: 1 3px;
  width: 44px;
}

.section-title-border-white {
  background-color: #fff;
  height: 2px;
  width: 100px;
}
.text_custom {
  color: #feb900;
}

.about_icon i {
  font-size: 22px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  display: inline-block;
  background: #fff; /* Initial background color */
  border-radius: 35px;
  color: #feb900; /* Initial icon color */
  box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for color change */
}

.about_icon i:hover {
  background-color: #feb900; /* Background color on hover */
  color: #fff; /* Icon color on hover */
}

.about_header_main .about_heading {
  max-width: 450px;
  font-size: 24px;
}

.about_icon span {
  position: relative;
  top: -10px;
}

.about_content_box_all {
  padding: 28px;
}

.has-children {
  position: relative;
  list-style: none; /* Remove default list styling */
}

/* The dropdown menu is hidden by default */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position below the parent */
  left: -130px; /* Adjust the left positioning as needed */
  background-color: white;
  list-style: none;
  padding: 0;
  margin-top: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Show the dropdown menu on hover */
.has-children:hover .dropdown-menu {
  display: block;
}

/* Style adjustments for dropdown links */
.dropdown-link {
  display: block;
  padding: 10px 20px;
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500px;
  line-height: 21px;
}

/* Style adjustments for dropdown links on hover */
.dropdown-link:hover {
  background-color: #f0f0f0;
  width: 100%;
}

@media (max-width: 786px) {
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%; /* Position below the parent */
    left: 0px; /* Adjust the left positioning as needed */
    background-color: white;
    list-style: none;
    padding: 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  .dropdown-link {
    display: block;
    padding: 2px 5px;
    color: black;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500px;
    line-height: 18px;
  }
}

.toggle-container {
  width: 1000px;
  margin: 10px auto;
}

.toggle-container::after {
  content: "";
  display: block;
  clear: both;
}

.toggle-button {
  transition: all 0.2s ease;
  display: block;
  cursor: pointer;
  float: left;
  width: calc(14% - 2px);
  color: rgba(24, 132, 165, 0.25);
  font-size: 16px;
  background: transparent;
  border-top: 2px solid #ebdaae;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 5px 10px;
  outline: none;
  margin: 10px;
}

.toggle-button.active {
  color: rgb(24, 132, 165);
  border-top: 2px solid #feb900;
}

.toggle-content {
  width: 70%;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
}

.content {
  display: none;
  padding: 20px;
}

.content.active {
  display: block;
}

@media (max-width: 768px) {
  .toggle-container {
    width: 300px;
    margin: 10px auto;
    padding-left: 100px;
  }
  .toggle-button {
    width: calc(50% - 10px); /* 50% width minus margins */
  }

  .toggle-content {
    display: flex;
    align-items: center;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  .content.active {
    display: block;
    margin-right: 72px;
  }
}

.image-card-about {
  margin-top: -4px;
}

/* -----------------------works page ------------------ */
/* WORKS HEADER */
.works-header {
  text-align: center;
  padding: 2rem;
}

.works-header h1.title {
  font-size: 3rem;
  font-weight: 700;
}

.works-header h2.second-title {
  font-size: 2.5rem;
  font-weight: 300;
}

/* WORKS SECTION */
.works-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 2rem 3%;
  text-align: center;
}

.works-section img {
  cursor: pointer;
  transition: opacity 400ms;
}

.works-section img:hover {
  opacity: 0.9;
}

/* ANIMATIONS */
.works-header h1.title,
.works-header h2.second-title,
.works-section img {
  animation: rise 400ms ease-out backwards;
}

.works-header h1.title {
  animation-delay: 100ms;
}

.works-header h2.second-title {
  animation-delay: 200ms;
}

.works-section img {
  animation-play-state: paused;
  animation-delay: 300ms;
  width: 100%;
}

@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
}

/* MEDIA QUERIES */
/* Tablets */
@media screen and (min-width: 480px) {
  .works-section {
    padding: 2rem 10% 10%;
  }
}

/* Small Screens, laptops */
@media screen and (min-width: 768px) {
  /* WORKS HEADER */
  .works-header {
    padding: 2rem 10%;
  }

  .works-header h1.title {
    font-size: 4.8rem;
  }

  .works-header h2.second-title {
    font-size: 3.4rem;
    margin-top: 1rem;
  }

  /* WORKS SECTION */
  .works-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktop, large screens */
@media screen and (min-width: 1024px) {
  /* WORKS HEADER */
  .works-header {
    padding: 2rem 10%;
  }

  .works-header h1.title {
    font-size: 4.8rem;
  }

  .works-header h2.second-title {
    font-size: 3.4rem;
    margin-top: 1rem;
  }

  /* WORKS SECTION */
  .works-section {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* ------------------about------------------------  */
.philosophy p {
  font-size: 18px;
  font-weight: 400px;
  font-family: "Futura Light BT", sans-serif;
}

.philosophy h2 {
  color: #153e77;
  font-family: "Futura Light BT", sans-serif;
}

@media (max-width: 786px) {
  .philosophy p {
    font-size: 15px;
    font-weight: 400px;
    font-family: "Futura Light BT", sans-serif;
  }
}

.banner-img {
  display: flex;
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  width: 140px; /* Adjust as needed */
  height: 110px;
  z-index: 999;
}

@media (max-width: 786px) {
  .banner-img {
    display: none;
  }
}

.about-img {
  width: 120px;
  height: 50px;
}

@media (max-width: 786px) {
  .about-img {
    width: 200px;
    height: 150px;
  }
}

.works-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  padding: 10px;
}

.works-section img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.bento-main {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bento-text {
  background-color: #eaecee;
  height: auto;
  padding: 5px 15px;
}

.bento-text p {
  text-align: left;
  line-height: 25px;
  font-size: 16px;
  font-family: "Futura Light BT", sans-serif;
}

.bento-text span {
  font-weight: 600;
}

.bentomain {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  color: black;
  margin: 80px 0px;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.bento {
  font-family: "Futura Light BT", sans-serif;
  font-weight: 500;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(80px, auto);
  gap: 1rem;
  height: 1000px;
  width: 60%;
}

.bento div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background: linear-gradient(145deg, #1e1f21, #2d2e30);
  overflow: hidden; /* Ensure images do not overflow the card borders */
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bento div:hover {
  transform: scale(1.02);
}

.bento img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the card area without distortion */
}

.card1 {
  grid-column: 1 / 5;
  grid-row: 1 / 4;
}

.card2 {
  grid-column: 5 / 9;
  grid-row: 1 / 4;
}

.card3 {
  grid-column: 1 / 5; /* Spans 4 columns */
  grid-row: 4 / 7; /* Positioned in the second row */
}

.card4 {
  grid-column: 5 / 9; /* Spans 4 columns */
  grid-row: 4 / 7; /* Positioned in the second row */
}

.card5 {
  grid-column: 1 / 5; /* Spans 4 columns */
  grid-row: 7 / 10; /* Positioned in the third row */
}

.card6 {
  grid-column: 5 / 9; /* Spans 4 columns */
  grid-row: 7 / 10; /* Positioned in the third row */
}

.card7 {
  grid-column: 1 / 3;
  grid-row: 10 / 14; /* Increased height */
}

.card11 {
  grid-column: 3 / 9;
  grid-row: 10 / 14; /* Increased height */
}

.card12 {
  grid-column: 1 / 9;
  grid-row: 14 / 16;
}

/* Adjustments for mobile view */
@media (max-width: 786px) {
  .bentomain {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    color: black;
    margin: 20px 0px;
    flex-direction: column-reverse;
    width: 100%;
    gap: auto;
  }

  .bento-main {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .bento {
    font-weight: 500;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust to have 4 columns */
    grid-auto-rows: minmax(80px, auto);
    gap: 1rem;
    height: auto; /* Adjusted for responsive height */
    width: 90%;
  }

  .card1 {
    grid-column: 1 / 5; /* Full width */
    grid-row: 1 / 3; /* Full height */
  }

  .card2 {
    grid-column: 1 / 5; /* Full width */
    grid-row: 3 / 5; /* Full height */
  }

  .card3 {
    grid-column: 1 / 3; /* Half width */
    grid-row: 5 / 7;
  }

  .card4 {
    grid-column: 3 / 5; /* Half width */
    grid-row: 5 / 7;
  }

  .card5 {
    grid-column: 1 / 3; /* Half width */
    grid-row: 7 / 9;
  }

  .card6 {
    grid-column: 3 / 5; /* Half width */
    grid-row: 7 / 9;
  }

  .card7 {
    grid-column: 1 / 5; /* Full width */
    grid-row: 9 / 12; /* Increased height */
  }

  .card11 {
    grid-column: 1 / 5; /* Full width */
    grid-row: 12 / 15; /* Increased height */
  }

  .card12 {
    grid-column: 1 / 5; /* Full width */
    grid-row: 15 / 16;
  }
}

.footer-links li {
  font-family: "Futura Light BT", sans-serif;
}

.Footer-2 {
  width: 100%;
  background-color: #222222;
  color: #ffff;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workpage-main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 0 20px; /* Added padding to ensure no side overflow */
}

.workgrid {
  display: flex;
  flex-direction: row; /* explicitly set row direction (though this is default) */
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  width: 70%;
  margin: 0 auto;
  gap: 20px; /* adds some space between items */
}

/* You may need to set widths on child elements */
.workgrid > * {
  flex: 0 1 300px; /* or whatever width you want items to be */
}

.workbutton-group {
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 30%;
  border-right: 5px solid var(--accent-color);
  padding-right: 40px;
  height: 500px;
}

.workbutton {
  border: 0;
  color: #333;
  cursor: pointer;
  background: transparent;
  padding-right: 40px;
  margin: 10px 0;
  font-family: "Futura Light BT", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.workbutton:hover {
  color: #333;
}

.workbutton.is-checked {
  color: var(--accent-color);
}

.workelement-item {
  position: relative;
  background-color: #d5d0d0;
  padding: 0;
  margin: 10px;
  width: calc(110% - 20px); /* Three items per row */
  height: 420px;
  overflow: hidden;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.workitem-image {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.workitem-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.workelement-item h1 {
  position: absolute;
  top: 185px;
  bottom: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  z-index: 2; /* Ensure the text is above other elements */
  text-align: right;
}
#work-element h1 {
  top: 150px;
}

.workelement-item h2 {
  text-align: right;
  position: absolute;
  bottom: 160px;
  right: 0;
  font-size: 28px;
}

.workitem-title {
  margin: 0;
  padding: 30px 5px 10px 5px;
  font-family: "Futura Light BT", sans-serif;
  font-size: 15px;
  font-weight: 700;
  overflow: hidden;
  text-align: center;
  height: 180px;
}

@media (max-width: 786px) {
  .workpage-main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .workbutton-group {
    margin: 0px auto;
    display: table;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-right: none;
    border-bottom: 5px solid var(--accent-color);
    margin-right: 0px;
    height: 300px;
  }

  .workbutton {
    border: 0;
    color: #333;
    cursor: pointer;
    background: transparent;
    padding-right: 0px;
    margin: 10px 10px;
    font-family: "Futura Light BT", sans-serif;
    font-size: 18px;
    font-weight: 700;
  }

  .workelement-item {
    width: 100%; /* Ensure each card takes up the full width */
    margin: 10px 0; /* Adjust margins for spacing between cards */
    display: block; /* Make sure each card is displayed in its own row */
  }

  .workgrid {
    flex-direction: column; /* Ensure the grid stacks the cards vertically */
    width: 80%; /* Ensure the grid takes full width */
  }

  .workelement-item h1 {
    font-size: 22px;
  }
  .workelement-item h2 {
    text-align: right;
    position: absolute;
    bottom: 180px;
    right: 0;
    font-size: 28px;
  }
  .workelement-item h1 {
    position: absolute;
    top: 165px;
    bottom: 10px;
    right: 10px;
    color: white;
    font-size: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    z-index: 2; /* Ensure the text is above other elements */
    text-align: right;
  }
  #work-element h1 {
    top: 130px;
  }
  .workitem-image img {
    width: 100%;
    height: 200px;
  }

  .workitem-title {
    margin: 0;
    padding: 30px 5px 10px 5px;
    font-family: "Futura Light BT", sans-serif;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
    text-align: center;
    height: 200px;
  }
}

.gallery-img {
  height: 310px;
}

.works-banner {
  position: absolute;
  right: 0;
  text-align: end;
  top: 220px;
}

@media (max-width: 786px) {
  .works-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-weight: 1000px;
    align-items: center;
  }
}

.vogelkop-video {
  width: 560px;
  height: 315px;
}

@media (max-width: 786px) {
  .vogelkop-video {
    width: 100%;
    height: 220px;
  }
}
